* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */


}

body {
  background-color: #333;
  color: #eee;
  font-family: Helvetica, Arial;
  font-size: 3vmin;
  overflow: hidden;
}

.App {
  text-align: center;
  position: fixed;
  width:100%;
  height:100%;
  transition:opacity 1.5s ease-in-out;
}

.App.inactive{
  pointer-events:none;
  opacity:0;
}



.pointer-all{
  pointer-events: all;
}



.ga-container{
  max-width:500px;
  position: relative;
}
.ga-background{
  width: 100%;
  position: absolute;
  background-color: #B5AAD5;
  background-image: url('./assets/ga-background.png');
  min-height:100vh;
  background-size: 100%;
}

.logo{
  width:90%;
  left:50%;
}

@font-face {
  font-family: 'retro';
  src: url('./assets/PublicPixel-z84yD.ttf') format('truetype');
}

.retro{
  font-family: 'retro', sans-serif;
  text-shadow: 0px 2px 0px #CE5937;
}

.retro-dropshadow{
  filter: drop-shadow(0px 5px 0px #CE5937);
}

.primary{
  color: #FFD8C7;
  text-shadow: 0px 2px 0px #CE5937;
}


.button-text{
  color: #FF6421 !important;
  text-shadow: 0px 2px 0px #B20303;
}

.secondary{
  color: #E0E1F6;
  text-shadow: 0px 2px 0px #666BD3;

}